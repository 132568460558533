import React, { Component } from "react"
import Header from "./header"
import Footer from "./footer"
import CookieConsent from 'react-cookie-consent';

import "../../css/layout.css"
class layout extends Component {
  state = {
    smallScreen: false,
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }
  render() {
    const { children, page } = this.props
    return (
      <div className="main">
        <Header page={page} smallScreen={this.state.smallScreen} />
        <div>{children}</div>
        <Footer />
          <CookieConsent 
		enableDeclineButton 
		debug={false}
		flipButtons
		style={{ background: "black" }}		
		location="bottom"
        buttonText="Accepteer"
        buttonStyle={{ color: "black", background: "white", fontSize: "17px" }}
        buttonId=""
        buttonClasses="acceptbutton"
        declineButtonText="Enkel functioneel"
        declineButtonClasses="declinebutton"
        containerClasses="alert alert-warning col-lg-12"
        contentClasses="text-capitalize"
        cookieName="gatsby-gdpr-google-analytics">
        Deze webite gebruikt cookies. Deze cookies zijn belangrijk voor het functioneren website. Wij respecteren uw privacy.
        Door het gebruiken van de site gaat u akkoord dat er cookies worden gebruikt.
		</CookieConsent>
      </div>
    )
  }
}

export default layout
