import React, { Component } from "react"
import { Link } from "gatsby"
import logo from "../../images/logo-large.svg"
import menuIcon from "../../images/menu.svg"
import { navigate } from 'gatsby'
import { Auth } from 'aws-amplify'
import { isLoggedIn, logout } from '../../services/auth'

class Header extends Component {
  openSidebar() {
    document.getElementById("sidebar").style.display = "block"
  }

  closeSidebar() {
    document.getElementById("sidebar").style.display = "none"
  }
  render() {
    let page = this.props.page
    return this.props.smallScreen ? (
      <div className="sidebar">
        <button onClick={this.openSidebar} className="sidebar-button-off">
          <img src={menuIcon} alt="Menu" className="menu-icon"></img>
        </button>
        <div
          class="sidebar-inner"
          style={{ display: "none" }}
          id="sidebar"
          onClick={this.closeSidebar}
        >
          <button className="sidebar-button-on">
            <img src={menuIcon} alt="Menu" className="menu-icon"></img>
          </button>
          <Link to="/" className="sidebar-link">
            <button className="sidebar-item">HOME</button>
          </Link>
          {isLoggedIn() ? (	
			  <Link to="/app/home" className="sidebar-link">
            <button className="sidebar-item">LESSEN</button>
          </Link>
			  ) : (
			  <Link to="/work" className="sidebar-link">
            <button className="sidebar-item">LESSEN</button>
          </Link>
			  )}
          <Link to="/about" className="sidebar-link">
            <button className="sidebar-item">OVER</button>
          </Link>
          <Link to="/books" className="sidebar-link">
            <button className="sidebar-item">BOEKEN</button>
          </Link>
          <Link to="/help" className="sidebar-link">
            <button className="sidebar-item">HULP</button>
          </Link>
          <Link to="/contact" className="sidebar-link">
            <button className="sidebar-item">CONTACT</button>
          </Link>
             {isLoggedIn() ? (
      <span
        onClick={() =>
          Auth.signOut()
          .then(logout(() => navigate('/app/login')))
          .catch(err => console.log('error:', err))
        }
        onKeyDown={() =>
          Auth.signOut()
          .then(logout(() => navigate('/app/login')))
          .catch(err => console.log('error:', err))
        }
        role="button"
        tabIndex="0"
      >
      <button className="mt-8 ml-8 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
      UITLOGGEN
      </button>
      </span>
    ) : (
      <span>
        <Link to="/app/login">
        <button className="mt-8 ml-8 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">INLOGGEN</button></Link>
      </span>
    )}
        </div>
      </div>
    ) : (
      <header className="header">
        <Link to="/">
          <button className={page === "home" ? "button-active" : "button"}>
            HOME
          </button>
        </Link>        
         {isLoggedIn() ? (
	         <Link to="/app/home">
	         <button className={page === "work" ? "button-active" : "button"}>
            LESSEN
          </button>
          </Link>
			  ) : (
			<Link to="/work">
			<button className={page === "work" ? "button-active" : "button"}>
            LESSEN
          </button>
          </Link>
			  )}
        <Link to="/about">
          <button className={page === "about" ? "button-active" : "button"}>
            OVER
          </button>
        </Link>
        <Link to="/books">
          <button className={page === "books" ? "button-active" : "button"}>
            BOEKEN
          </button>
        </Link>
        <Link to="/help">
          <button className={page === "help" ? "button-active" : "button"}>
            HULP
          </button>
        </Link>
        <Link to="/contact">
          <button className={page === "contact" ? "button-active" : "button"}>
            CONTACT
          </button>
        </Link>

    {isLoggedIn() ? (
      <span
        onClick={() =>
          Auth.signOut()
          .then(logout(() => navigate('/app/login')))
          .catch(err => console.log('error:', err))
        }
        onKeyDown={() =>
          Auth.signOut()
          .then(logout(() => navigate('/app/login')))
          .catch(err => console.log('error:', err))
        }
        role="button"
        tabIndex="0"
      >
      <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
      UITLOGGEN
      </button>
      </span>
    ) : (
      <span>
        <Link to="/app/login">
        <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">INLOGGEN</button></Link>
      </span>
    )}

      </header>
    )
  }
}
export default Header
