import React, { Component } from "react"

class Footer extends Component {
  render() {
    return (
      <footer className="footer">     
        <a className="footer-left" href="/privacypolicy/index.html">Privacybeleid</a> 
        <a className="footer-left" href="/termsandconditions/index.html">Gebruiksvoorwaarden</a> 
        <a href="https://mighty-real.com" className="footer-text">
          © {new Date().getFullYear()}
          {` `} This website has been created by Mighty Real ICT & Media Solutions.
        </a>
      </footer>    
    )
  }
}
export default Footer
